import React from "react";
import UserUpdate from "./update/index";
import User from "../../components/user";
import Layout from "../../components/layout";
import Container from "../../common/components/container";

const Index = () => {
  return (
    <Layout>
      <Container>
        <User />

        {/* <UserUpdate /> */}
      </Container>
    </Layout>
  );
};

export default Index;
